// Note: This looks overengineered now for a single feature - but there are at least 4 other scroll
// based animations Luke has asked for. This is in preparation for those :)

export default function initScrolliness() {
	const $siteBackground = document.querySelector("[data-background]");
	if (!$siteBackground) {
		return;
	}

	return new Scrolliness();
}

class Scrolliness {
	constructor() {
		this.features = {};
		this.pageOffset = scrollTop();

		this.createFeatures();

		document.addEventListener("scroll", this.savePosition.bind(this), {
			passive: true
		});
		document.addEventListener("touchmove", this.savePosition.bind(this), {
			passive: true
		});

		this.prepareFeatures();
		this.runFrame();
	}

	restart() {
		this.destroyFeatures();
		this.createFeatures();
		this.prepareFeatures();
	}

	createFeatures() {
		this.features.background = {
			triggerPoint: null,
			dependants: {
				container: document.body
			},
			process: function process() {
				this.triggerPoint = document.querySelector(
					".l-site-header"
				).offsetHeight;
			},
			test: function test(pageOffset) {
				if (this.triggerPoint !== null) {
					if (pageOffset >= this.triggerPoint) {
						this.dependants.container.classList.add("has-dark-background");
					} else {
						this.dependants.container.classList.remove("has-dark-background");
					}
				}
			}
		};
	}

	prepareFeatures() {
		for (let key in this.features) {
			if (this.features.hasOwnProperty(key)) {
				this.features[key].process();
			}
		}
	}

	runFeatures() {
		for (let key in this.features) {
			if (this.features.hasOwnProperty(key)) {
				this.features[key].test(this.pageOffset);
			}
		}
	}

	destroyFeatures() {
		this.features = {};
	}

	savePosition() {
		this.pageOffset = scrollTop();
	}

	runFrame() {
		requestAnimationFrame(this.runFrame.bind(this));

		this.runFeatures();
	}
}

function scrollTop() {
	return document.documentElement.scrollTop || document.body.scrollTop;
}

function offsetFromTop(el) {
	var rect = el.getBoundingClientRect();
	var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
	var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}
