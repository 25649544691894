import CountdownTimer from "@10up/countdown-timer";

export default function initCountdown() {
	if (!document.querySelector("[data-countdown]")) {
		return;
	}

	new CountdownTimer("[data-countdown]", {
		years: {
			allowed: false
		},
		weeks: {
			allowed: true,
			singular: "",
			plural: ""
		},
		days: {
			allowed: true,
			singular: "",
			plural: ""
		},
		hours: {
			allowed: true,
			singular: "",
			plural: ""
		},
		minutes: {
			allowed: true,
			singular: "",
			plural: ""
		},
		seconds: {
			allowed: true,
			singular: "",
			plural: ""
		},
		showZeroes: true,
		separator: ""
	});
}
